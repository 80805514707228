
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const Neurofeedback = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("neurofeedbackSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-neuro'>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Future-Ready:</h4>
            <hr className='mt-5'></hr>
          </section>
          
          <section className='vp-section'>
              <div className='section-contaner'>

                <div className='row'>
                  <div className='col-12 col-md-6 flex-column align-items-start inner-container gap-5 '>

                  <div className='mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg '>
                        <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh' >Comprehensive Preparation</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Designed to prepare students for the academic challenges of the next level, whatever it might be, including critical thinking, research skills, and advanced learning techniques.</p>
                        {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Mood modulation for improved learning focus.</p>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Individualized feedback for enhanced learning efficiency.</p> */}

                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>

                  <div className='mb-0 mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg'>
                        <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h6>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Workforce Readiness</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Integrates practical skills training and real-world applications into the learning experience, ensuring students are well-equipped for the modern workforce and emerging job markets.</p>
                        {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Evidence-based techniques to support learning processes.</p>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Aims to make learning experiences more productive and targeted.</p> */}

                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>

                  <div className='mb-0 mb-0 w-100'>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                      <div className='job-width lh-lg'>
                        <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                        <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Adaptability and Future Skills</span>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Emphasizes the development of adaptable, future-proof skills such as digital literacy, problem-solving, and collaboration, preparing students for the rapidly changing nature of work.</p>
                        {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Offers tailored educational approaches for each learner.</p>
                        <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Focuses on improving learning outcomes through direct feedback.</p>
                      */}
                      </div>
                      <div><i className='demo-icon icon-checkiconsolid' /></div>
                    </div>
                  </div>
                </div>
                <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                    <img src='/images/Neuro Biofeedback.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>


          <section className='vp-section'>
            <div className='justify-content-center'>
              <div className=' d-flex flex-column align-items-start text-left gap-5'>
                <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
                Prepare for Tomorrow's World Today with Real Merit Academy 
                </p>
                <div className="width-100 ">
                  <img src='/images/Future Ready Wide.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                </div>
              </div>
            </div>
          </section>

          
          <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Real Merit Academy is meticulously built to bridge the gap between academic learning and real-world applications, ensuring students are academic-ready and workforce-prepared.
                   </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                We prioritize skills that are essential for success in the future workplace, including technological proficiency, critical analysis, moral reasoning, and effective communication.                </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  We prioritize skills that are essential for success in the future workplace, including technological proficiency, critical analysis, moral reasoning, and effective communication..                </p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
                    Our approach to education is forward-thinking, focusing on adaptability and lifelong learning to keep pace with the evolution of the job market.
                                     </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                 
                            </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Classroom Image 4.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  By fostering a mindset of innovation and continuous improvement, we prepare students to navigate the challenges and opportunities of the future with confidence.              </p>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                By fostering a mindset of innovation and continuous improvement, we prepare students to navigate the challenges and opportunities of the future with confidence. 
                            </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Classroom Image 2.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Real Merit Academy is your partner in building a solid foundation for a successful career, offering personalized guidance and support every step of the way.              </p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic  common-textshadow text-start lh-lg ft-3vh'>
   
                    Step Into Your Future with Confidence. Click 'Meet Us' to explore how Real Merit Academy prepares you for academic success and career excellence.
                      </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Classroom Image 1.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
              <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                        <MyPopupbutton/>
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default Neurofeedback;

