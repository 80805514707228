// ScrollItems.js
import React from 'react';
import '../global.css';
import CommomScroll from './CommomScroll'
import Users from './Users';
import Research from '../research/research';

const ScrollItems = () => {
  const blogUrls = [
    "https://pubmed.ncbi.nlm.nih.gov/15564054/",
    "https://www.tandfonline.com/doi/abs/10.1080/00461520.2010.515933",
    "https://bera-journals.onlinelibrary.wiley.com/doi/abs/10.1080/01411926.2010.501096"
  ];

  const researchUrls = [
    "https://pubmed.ncbi.nlm.nih.gov/15564054/",
    "https://www.tandfonline.com/doi/abs/10.1080/00461520.2010.515933",
    "https://bera-journals.onlinelibrary.wiley.com/doi/abs/10.1080/01411926.2010.501096"
  ];
  return (
    <div className='container-fluid bg-dark p-0'>
      <div className="scroll-items bg-dark vp-container pt-4">
        {/* Your scroll items/content go here */}
        <div class="row  gap-5 mt-5">
          <div class="col-md">
            <span class='custom-large-heading light-col'>Features</span>
          </div>
          <div class="col-md">
            <span class='montser-sml-LightItalic light-col ft-section-heading'>Empower, Achieve, Adapt: At Real Merit Academy, we blend critical thinking, merit-based progression, and intelligent adaptation to unlock your true potential. Discover education that evolves with you, ensuring success in a changing world. Your path to excellence, personalized .</span>
          </div>
        </div>

        <div className="row text-yellow pt-2 gx-5 img-spacing">
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/criticalthinking " className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text montser-mdm-2 common-textshadow ">Critical Thinking</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Personalized, adaptive, engaging</span>
                </div>
                <img src='images/critical_thinking_square_2.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"  ></img>

              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/futureready" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text scroll-text common-textshadow  montser-mdm-2 ">Future Ready</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Sharpen focus, Supercharge memory</span>
                </div>
                <img src='images/future_ready_square_2.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/specialneeds" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow montser-mdm-2  ">Intelligent Adaptation</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Education for all abilities</span>
                </div>
                <img src='images/intelligent_adaptation_square_2_720.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/meritbased" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow  montser-mdm-2">Merit Based Education</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Embrace cultural insights</span>
                </div>
                <img src='images/merit_based_education_2.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/neuroempowerment" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 scroll-text text-yellow common-textshadow  montser-mdm-2 ">Neuro Empowerment</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Grow Your brain</span>
                </div>
                <img src='images/neuro_empowerment_square_2.webp' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-4">
            <div className="card border-0 text-yellow bg-transparent">
              <a href="/features/intelligentadaptation" className="text-decoration-none" >
                <div className='d-flex flex-column headings'>
                  <span className="scroll-item mb-1 scroll-text text-yellow common-textshadow  montser-mdm-2 ">Special Needs Education</span>
                  <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Streamlined educational administration</span>
                </div>
                <img src='images/Special Needs Square.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
              </a>
            </div>
          </div>
          <div className="col-12">
            <hr className='mt-8rem'></hr>
          </div>
        </div>
      </div>
      <CommomScroll title="Blog" urls={blogUrls} />
      <Research />
      <Users />
    </div>

  );
};

export default ScrollItems;