// ScrollItems.js
import React from 'react';
import '../global.css'; 
import CommomScroll from './CommomScroll'

const Users = () => {
  const blogUrls = [
    "https://pubmed.ncbi.nlm.nih.gov/15564054/",
    "https://www.tandfonline.com/doi/abs/10.1080/00461520.2010.515933",
    "https://bera-journals.onlinelibrary.wiley.com/doi/abs/10.1080/01411926.2010.501096"
  ];

  const researchUrls = [
    "https://pubmed.ncbi.nlm.nih.gov/15564054/",
    "https://www.tandfonline.com/doi/abs/10.1080/00461520.2010.515933",
    "https://bera-journals.onlinelibrary.wiley.com/doi/abs/10.1080/01411926.2010.501096"
  ];
    return (
      <div>
    <div className="scroll-items bg-dark vp-container pt-4">
  {/* Your scroll items/content go here */}
    <div class="row gap-5">
      <div class="col-md">
        <span class='custom-large-heading light-col'>Users</span>
      </div>
      <div class="col-md">
        <span class='montser-sml-LightItalic light-col ft-section-heading'>Discover how Virtu Prep will cater to you with a learning environment tailored to your specific needs and goals</span>
      </div>
    </div>

    <div className="row text-yellow pt-2 gx-5 img-spacing">
    <div className="col-md-6 col-lg-4 my-4">
      <div className="card border-0 text-yellow bg-transparent">
      <a href="/students" className="text-decoration-none" >
      <div className='d-flex flex-column headings'>
        <span className="scroll-item mb-1 text-yellow  scroll-text montser-mdm-2 common-textshadow">Students</span>
        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Discover the future of learning with Virtu Prep!</span>
        </div>
        <img src='images/Students_Image_1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"  ></img>
      </a>
      </div>
    </div>
    <div className="col-md-6 col-lg-4 my-4">
      {/* <div className="card border-0 text-yellow bg-transparent">
      <a href="/teachers" className="text-decoration-none" >
      <div className='d-flex flex-column headings'>
        <span className="scroll-item mb-1 text-yellow scroll-text scroll-text common-textshadow  montser-mdm-2">Teachers</span>
        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic ">Elevate your teaching experience!</span>
       </div>
        <img src='images/Teachers_Image_1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
      </a>
      </div> */}
    </div>
    <div className="col-md-6 col-lg-4 my-4">
      <div className="card border-0 text-yellow bg-transparent">
        <a href="/parents" className="text-decoration-none" >
        <div className='d-flex flex-column headings'>
          <span className="scroll-item mb-1 text-yellow  scroll-text common-textshadow montser-mdm-2">Parents</span>
          <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Discover the power of Virtu Prep for your child's academic success!</span>
          </div>
          <img src='images/Parents_Image_1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
      </a>
      </div>
    </div>
    {/* <div className="col-md-6 col-lg-4 my-4">
      <div className="card border-0 text-yellow bg-transparent">
      <a href="/administrators" className="text-decoration-none" >
        <div className='d-flex flex-column headings'>
        <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow  montser-mdm-2 ">Administrators</span>
        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Drive the evolution of your school's learning environment!</span>
        </div>
        <img src='images/Administrator_Image_1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
      </a>
      </div>
    </div> */}
    {/* <div className="col-md-6 col-lg-4 my-4">
      <div className="card border-0 text-yellow bg-transparent">
      <a href="/tutors" className="text-decoration-none" >
        <div className='d-flex flex-column headings'>
        <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow  montser-mdm-2">Tutors</span>
        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Elevate your tutoring business with Virtu Prep's AI-driven platform!</span>
        </div>
        <img src='images/Tutor_Image_1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
        </a>      
      </div>
    </div> */}
    <div className="col-md-6 col-lg-4 my-4">
     {/*  <div className="card border-0 text-yellow bg-transparent">
      <a href="/corporations" className="text-decoration-none" >
        <div className='d-flex flex-column headings'>
        <span className="scroll-item mb-1 text-yellow scroll-text common-textshadow  montser-mdm-2">Corporations</span>
        <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">Experience the future of corporate training and professional development!</span>
        </div>
        <img src='images/Corporations_Image_1.png' alt="image not found" className="img-fluid rounded-top rounded-0 common-boxshadow"></img>
        </a>
      </div> */}
    </div>
    <div className="col-12">
    </div>
  </div>
</div>
</div>
  );
};

export default Users;