// App.js
import React, { useEffect } from 'react';
import VideoBackground from './components/VideoBackground';
import ScrollItems from './components/ScrollItems';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Students from './sections/Students';
import Parents from './sections/Parents';
import Corporations from './sections/Corporation';
import Footer from './components/Footer';
import Administrators from './sections/Administrators'
import Teachers from './sections/Teachers'
import Tutors from './sections/Tutors'
import "@fontsource/montserrat";
import EnhancedAi from './Features/EnhancedAi';
import Neurofeedback from './Features/Neurofeedback';
import SpecialNeeds from './Features/SpecialNeeds';
import Culture from './Features/Culture';
import Braintraining from './Features/Braintraining';
import Managementsolutions from './Features/Managementsolutions';
import Blog_1 from './blogs/Blog_1';
import BlogDashboard from './blogs/BlogDashboard';
import Aitutor from './blogs/Aitutor';
import Education from './blogs/Education';
import Repetition from './blogs/Repetition';
import Shadows from './blogs/Shadows';
import Kaleidoscope from './blogs/Kaleidoscope';
import Matrix from './blogs/Matrix';
import Neuroscience from './blogs/Neuroscience';
import ResearchDashboard from './research/ResearchDashboard';
import Aboutus from './about_us/Aboutus';


const App = () => {
  const dynamicVideoLink = "videos/Real Merit Landing 1 Vid.mp4";
  const pageName = "mainPage"
  const buttonText = "About Real Merit"

  useEffect(() => {
    window.addEventListener('resize', adjustHeightWidth);
    adjustHeightWidth(); // Call the function initially

    return () => {
      window.removeEventListener('resize', adjustHeightWidth);
    };
  }, []);

  const adjustHeightWidth = () => {
    const viewportHeight = window.innerHeight; 
    const viewportWidth = window.innerWidth; 

    const setElementSize = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.height = `${viewportHeight}px`;
        element.style.width = `${viewportWidth}px`;
      }
    };
  
    setElementSize("mainPage");
  } 
  return (
    <Router>
      <div className='mainSectionDiv'>
        <Routes>
        <Route
            path="/students"
            element={
              <>
                <Navbar />
                <Students />
              </>
            }
          />
          <Route path="/parents" element={<Parents />} />
          <Route path="/corporations" element={<Corporations />} />
          <Route path="/videoBackground" element={<VideoBackground />} />
          <Route path="/administrators" element={<Administrators />} />
          <Route path="/teachers" element={<Teachers />} />
          <Route path="/tutors" element={<Tutors />} />
          <Route path="/features/criticalthinking" element={<EnhancedAi />} />
          <Route path="/features/futureready" element={<Neurofeedback />} />
          <Route path="/features/specialneeds" element={<SpecialNeeds />} />
          <Route path="/features/meritbased" element={<Culture />} />
          <Route path="/features/neuroempowerment" element={<Braintraining />} />
          <Route path="/features/intelligentadaptation" element={<Managementsolutions />} />
          <Route path="/blog" element={<Blog_1 />} />
          <Route path="/blogdashboard" element={<BlogDashboard />} />
          <Route path="/blog1" element={<Aitutor />} />
          <Route path="/blog2" element={<Education />} />
          <Route path="/blog3" element={<Repetition />} />
          <Route path="/blog4" element={<Shadows />} />
          <Route path="/blog5" element={<Kaleidoscope />} />
          <Route path="/blog6" element={<Matrix />} />
          <Route path="/blog7" element={<Neuroscience />} />
          <Route path="/research" element={<ResearchDashboard />} />
          <Route path="/about-us" element={<Aboutus />} />


          
          <Route path="/" element={<>
        <Navbar />
            {/* Only include components for the home route */}
            <VideoBackground
              videoLink={dynamicVideoLink}
              buttonText={buttonText}
              pageName={pageName}
            />
            <ScrollItems />
            <Footer />
          </>} />
        </Routes>
      </div>
    </Router>
    
  );
};

export default App;
