
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MyPopupbutton from '../react-calendly/MyPopupbutton';

const Managementsolutions = () => {
  useEffect(() => {
    const elements = document.getElementsByClassName("text-yellow");

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("managementsolutionsSectionActive");
  }, []);

  return (
    // <>

    <div className=''>
      {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
      {/* <> */}
      <Navbar />
      <div style={{ height: '100%', paddingTop: '100px' }} className='bg-body-management'>
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section>
            <h4 className='mb-3 custom-large-heading section-margin'>Intelligent Adaptation</h4>
            <hr className='mt-5'></hr>
          </section>
          
          <section className='vp-section'>
              <div className='section-contaner'>

                <div className='row'>
                  <div className='col-12 col-md-6 flex-column align-items-start inner-container gap-5 '>

                    <div className='mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg '>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh' >Customized Learning Paths</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Tailors the educational journey for each student using AI-driven analytics, ensuring a personalized learning experience that adapts to individual strengths and needs.</p>  
                          {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Allows for a focus on delivering high-quality learning experiences.</p>    
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'> Enhances efficiency in managing educational programs.</p>
                        */}
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h6 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h6>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Dynamic Content Adjustment</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Utilizes cutting-edge algorithms to dynamically adjust learning materials and challenges in real-time, based on the student's performance and engagement level.</p>  
                          {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Enables effective and efficient educational program management.</p>    
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'> Facilitates better resource allocation and time management.</p>
                        */}
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>

                    <div className='mb-0 mb-0 w-100'>
                      <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                        <div className='job-width lh-lg'>
                          <h5 className='ommon-textshadow  montser-mdm-2 ft-4vh '>FUNCTION:</h5>
                          <span className='montser-mdm-2 common-textshadow ft-2-5vh'>Responsive Educational Strategies</span>
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Implements adaptive learning strategies that respond to the evolving needs of students, facilitating optimal learning outcomes through personalized feedback and support.</p>  
                          {/* <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>Integrates advanced tools for comprehensive educational management.</p>    
                          <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'> Supports the future of education with innovative management solutions.</p>
                         */}
                        </div>
                        <div><i className='demo-icon icon-checkiconsolid' /></div>
                      </div>
                    </div>            
                  </div>
                  <div className='col-md-5 col-12 mt-3 mt-md-0 d-flex align-items-center'>
                    <div className='w-100 maxw-450 ms-auto d-flex mt-5 image-container'>
                      <img src='/images/Teachers.jpeg' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section className='vp-section'>
            <div className='justify-content-center'>
              <div className=' d-flex flex-column align-items-start text-left gap-5'>
                <p className='montser-mdm-2 vp-container common-textshadow mb-3 text-center lh-lg ft-3vh'>
                Experience Personalized Learning with Intelligent Adaptation!
                </p>
                <div className="width-100 ">
                  <img src='/images/Intelligent Adaptation Wide.png' alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                </div>
              </div>
            </div>
          </section>

         

          
          <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <section className='vp-section'>
            <div className='row align-items-center'>
          
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 2.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                  Real Merit Academy leverages the latest in artificial intelligence to provide a truly customized education, where learning paths are individually tailored to maximize each student's potential
                  
                </p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic common-textshadow text-start lh-lg ft-3vh'>
                    Our intelligent adaptation system dynamically adjusts content and challenges, ensuring that students are always engaged and progressing at the pace that's right for them  </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Brain Training 1.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>


          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Our intelligent adaptation system dynamically adjusts content and challenges, ensuring that students are always engaged and progressing at the pace that's right for them.
                </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Brain Training 3.png' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                    
                  By focusing on responsive educational strategies, we equip students with the tools and support they need to excel academically and prepare for the future.
                </p>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>
                    <p className='montser-sml-LightItalic common-textshadow text-start lh-lg ft-3vh'>
                    Intelligent Adaptation at Real Merit Academy means that every student's learning experience is unique, adaptive, and designed to foster growth, understanding, and achievement </p>                    
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Classroom Image 4.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
            </div>
          </section>

          <section className='vp-section'>
            <div className='row align-items-center'>
            {/* <div className='col-sm-5 d-block d-sm-none'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0"><p className="montser-sml-LightItalic lh-lg ft-3vh">
                Our intelligent adaptation system dynamically adjusts content and challenges, ensuring that students are always engaged and progressing at the pace that's right for them.
                </p>
                  </div>
                </div>
              </div> */}
            <div className='col-sm-7'>
                  <div className='w-100 maxw-450 me-auto d-flex mt-5'>
                    <img src='/images/Classroom Image 1.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />

                  </div>
              </div>
              <div className='col-sm-5 d-none d-sm-block'>
                <div className='align-items-center inner-container gap-5'>
                <div className="mt-3 mt-sm-0">
                  <p className="montser-sml-LightItalic lh-lg ft-3vh">
                    
                  Join us on a journey where technology and education merge to create a learning environment that is innovative, effective, and truly personalized.
                </p>
                    </div>
                </div>
              </div>
            </div>
          </section>
          
          
          
          
          
          <section className='vp-section'>
            <div className='row align-items-center'>
              <div className='col-sm-5'>
                <div className='align-items-center inner-container gap-5'>
                  <div className=' justify'>

                    <p className='montser-sml-LightItalic common-textshadow text-start lh-lg ft-3vh'>
                    Discover the Future of Education. Click 'Meet Us' to learn more about Intelligent Adaptation at Real Merit Academy and how it can transform your learning experience.
                      </p>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                  <div className='w-100 maxw-450 ms-auto d-flex mt-5'>
                    <img src='/images/Classroom Image 3.webp' alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                  </div>
              </div>
              <div className="row ">
                    <div className='col-sm-5'>
                      < div className='d-flex align-items-center mt-4 mt-sm-0 justify-content-center footer'>
                          <MyPopupbutton/>
                        </div>
                    </div>
                    <div className='col-sm-7'></div>
                  </div>
            </div>
          </section>


          
        </div>
        <Footer />
      </div>
      {/* </> */}
    </div>
    // </div>
    // </>


  );
};

export default Managementsolutions;

