// Students.js
import React from 'react';
import { useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import './aboutus.css';
import VideoBackground from '../components/VideoBackground';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Aboutus = () => {
    const dynamicVideoLink = "videos/Sequence 01_2.mp4";
    const pageName = "aboutPage"
    const buttonText = "Home"

    useEffect(() => {
        const elements = document.getElementsByClassName("text-yellow");

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add(".text-dark1");
        }
        const section = document.getElementsByClassName("mainSectionDiv")[0];
        section.classList.add("aboutSectionActive");
    }, []);

    return (
        // <>

        <div className=''>
            {/* <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'bg-dark' }} className='bg-body'> */}
            {/* <> */}
            <Navbar />
            <VideoBackground
                videoLink={dynamicVideoLink}
                buttonText={buttonText}
                pageName={pageName}
            />
            <div style={{ height: '100%', paddingTop: '20px' }} className='bg-body-about'>
                <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
                    <div className='content-margin '>
                       
                       
                       
                        <div className='mt-2 pt-3'>
                        <heding className='montser-lrg-bold align-items-center py-4'>A Revolution in Education: Merit-Based, Critical Thinking-Driven Learning: </heding>

                        <p className='align-items-center monster-lrg-normal p-gap ' style={{ paddingTop: '1.5rem' }}>
                        Welcome to Real Merit Academy, where education transcends conventional boundaries to offer a merit-based, critical thinking-driven learning experience. At the core of our mission is the belief that education should be as distinctive and multifaceted as the learners it serves. Our academy pioneers in delivering an education that values real merit, critical thinking, moral reasoning, and real-world skills above all.
                        </p>
                       
           
                        <heding className='montser-lrg-bold align-items-center py-4'>Our Vision: Cultivating Excellence Through Critical Analysis and Merit</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        Real Merit Academy stands as a beacon for those who seek an education that genuinely reflects their aspirations and abilities. We champion a philosophy that recognizes the importance of nurturing each student's potential through rigorous intellectual engagement and meritocratic achievement. Our commitment is to provide an educational journey that is tailored to the individual, promoting a culture of excellence, accountability, and continuous growth and improvement.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        Our educational model is built on the pillars of personalized learning and critical thinking. By integrating advanced science-based strategies and leveraging data-driven insights, we ensure that our academics match the unique needs of each student. This approach is enhanced by fostering an environment where critical thinking and ethical decision-making is not just encouraged but is the cornerstone of all learning activities, preparing students for the complexities of the real world.
                        </p>
                        

                        <heding className='montser-lrg-bold align-items-center py-4'>Empowering Learners with Real Skills for the Future:</heding>

                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        Real Merit Academy is dedicated to equipping students with the skills necessary to navigate the challenges of tomorrow. Through a focus on real-world applications and problem-solving, we prepare our students for success in academics, their careers, and beyond. Our platform and programs are designed to make education accessible to all, breaking down barriers to quality education for diverse learners, including those with special needs and from underserved communities.
                        </p>
                        <p className='align-items-center monster-lrg-normal pt-1-5rem'>
                        We believe in empowering students, educators, and parents, providing them with the tools and resources needed to support the learner's journey. Through comprehensive analytics and feedback mechanisms, we offer insights into student progress, enabling personalized and adaptive learning experiences that drive real achievement.
                         </p>

                        <heding className='montser-lrg-bold align-items-center py-4'>Beyond the Classroom: A Community of Lifelong Learners:</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        At Real Merit Academy, education extends beyond academic knowledge. We emphasize the development of life skills, such as resilience, independence, and ethical decision-making. Our aim is to foster not just academic success but to cultivate well-rounded individuals ready to contribute positively to society.
                        </p>
                        <p className='align-items-center monster-lrg-normal p-gap '>
                        As we look towards the future, Real Merit Academy remains committed to innovation, security, and privacy. Informed by feedback, research, and technological advancements, we continually strive to enhance our educational offerings, ensuring they meet the highest standards of honest and effectiveness.
                        </p>
                        
                        <heding className='montser-lrg-bold align-items-center py-4'>Join Us at Real Merit Academy:</heding>
                        <p className='align-items-center monster-lrg-normal py-4'>
                        We invite you to become part of Real Merit Academy, a community dedicated to achieving excellence through critical thinking and merit-based learning. Join us on this journey of discovery, growth, and unparalleled education. Welcome to the forefront of educational innovation. Welcome to Real Merit Academy.
                        </p>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
            {/* </> */}
        </div>
        // </div>
        // </>


    );
};

export default Aboutus;

